import { auth } from 'config/base';

export default {
  async fetchAsAdmin(method, requestURL, bodyData) {
    try {
      const token = await auth.currentUser.getIdToken();
      const requestBody = {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      if (bodyData) requestBody.body = JSON.stringify(bodyData);

      const response = await fetch(requestURL, requestBody);

      const contentType = response.headers.get('content-type');
      const isJson = contentType && contentType.indexOf('application/json') !== -1;
      let data = '';
      if (isJson) {
        const json = await response.json();
        data = json.data ? json.data : json;
      } else {
        data = await response.text();
      }

      if (!response.ok) throw new Error(data.error);
      if (response.status === 204) return [];

      return Array.isArray(data) ? data : [data];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchBlobWithAuth(method = 'GET', url, bodyData) {
    try {
      const token = await auth.currentUser.getIdToken();
      const options = {
        method,
        headers: {
          Accept: 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      };

      if (bodyData) {
        options.body = JSON.stringify(bodyData);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Failed to fetch blob: ${response.status} ${response.statusText}`);
      }

      // Return the response as a Blob
      return await response.blob();
    } catch (error) {
      console.error('Error fetching blob with auth:', error);
      throw error;
    }
  },

  async simpleGet(method, requestURL) {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        mode: 'no-cors',
      };
      await fetch(requestURL, requestOptions);

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
