import React, { Component } from 'react';
import orderActions from 'actions/orderActions';
import fulfillmentActions from 'actions/fulfillmentActions';
import ScanQrToOrder from 'components/helpers/ScanQrToOrder';
import FulfillmentContentPanelOriginalPictures from 'components/pages/fulfillment/FulfillmentContentPanelOriginalPictures';
import { consoleUrl, config } from 'config/base';

class FulfillmentContentPanelPictureOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: {},
      thumbnails: {},
      showUserPictures: false,
      metadata: {},
    };
  }

  componentDidMount = () => this.getData();

  getMetadata = async () => {
    const userId = this.props.userId ? this.props.userId : this.props.match.params.userId;
    const fulfillmentId = this.props.fulfillmentId ? this.props.fulfillmentId : this.props.match.params.fulfillmentId;
    const pictureOrder = await orderActions.getPictureOrder(userId, fulfillmentId).catch(() => {
      return [
        {
          metadata: {
            platform: 'reprint',
            version: '',
          },
        },
      ];
    });
    const metadata = pictureOrder?.[0]?.metadata;

    this.setState({ metadata });
  };

  getData = async () => {
    if (!this.props.link) return;
    const [pictures, thumbnails] = await orderActions.getPictureOrderPictures(this.props.link);
    await this.getMetadata();
    this.setState({ pictures, thumbnails });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate = async newProps => {
    if (newProps.userId === this.props.userId) return;
    if (newProps.orderId === this.props.orderId) return;
    await this.getData();
  };

  changeToOriginals = () => {
    const { pictures } = this.state;
    this.setState({ thumbnails: pictures });

    this.props.toastOpen('Images changed to originals. You can click on them or save them to get the full size.');
  };

  reorderFuji = async () => {
    try {
      const orderId = this.props.orderId ? this.props.orderId : this.props.match.params.orderId;
      this.props.toastOpen(`(Re)ordering prints at Fuji...`);
      const result = await fulfillmentActions.createFujiOrder(this.props.userId, orderId, false);
      const { OrderID } = result[0];
      this.props.toastOpen(`Order succesfully (re)ordered at Fuji with id: ${OrderID}`);

      return true;
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error);
    }
  };

  render() {
    const { thumbnails, showUserPictures, metadata } = this.state;
    const { fulfillmentId, orderId, userId, packageId } = this.props;

    return (
      <span style={{ padding: '20px' }}>
        <button type="button" onClick={() => this.setState({ showUserPictures: !showUserPictures })}>
          {showUserPictures ? 'Hide ' : 'Show '}Original Pictures
        </button>
        <a
          className="button"
          href={`${consoleUrl}storage/${config.storageBucket}/files/~2Fuser~2F${userId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Storage
        </a>
        {showUserPictures && (
          <FulfillmentContentPanelOriginalPictures
            fulfillmentId={fulfillmentId}
            orderId={orderId}
            userId={userId}
            toastOpen={this.props.toastOpen}
          />
        )}
        <button type="button" onClick={this.changeToOriginals}>
          Get Full Size
        </button>
        {packageId === '901' && (
          <button type="button" onClick={this.reorderFuji}>
            Reorder Fuji
          </button>
        )}
        {metadata && (
          <span
            style={{
              background: 'rgb(202, 234, 239)',
              borderRadius: '30px',
              fontSize: '14px',
              textAlign: 'center',
              width: 'fit-content',
              padding: '0px 16px',
              color: 'rgb(3, 84, 95)',
              height: '30px',
              lineHeight: '30px',
              textTransform: 'uppercase',
              display: 'inline-block',
            }}
          >
            {metadata?.platform} - {metadata?.version}
          </span>
        )}
        {Object.keys(thumbnails).length < 1 && (
          <div style={{ padding: '20px' }}>We could not find any thumbnails try rendering.</div>
        )}
        {thumbnails &&
          Object.entries(thumbnails).map(([key, values]) => (
            <div className="pictureOrder" key={key}>
              <h2>{key}</h2>
              {values.map((value, i) => (
                <div className="picture-container" key={key + value}>
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    <img src={value} className="picture" alt={i} />
                  </a>
                </div>
              ))}
            </div>
          ))}
        <ScanQrToOrder history={this.props.history} />
      </span>
    );
  }
}

export default FulfillmentContentPanelPictureOrder;
