import React, { useState, useEffect } from 'react';
import Spinner from 'components/helpers/Spinner';
import TableEmpty from 'components/helpers/TableEmpty';
import PropTypes from 'prop-types';

const ThumbnailLoader = ({ fetchThumbnail, alt, style }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadThumbnail = async () => {
      try {
        setLoading(true);
        const thumbnailBinary = await fetchThumbnail();
        const blob = new Blob([thumbnailBinary], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        setThumbnail(url);
      } catch (e) {
        console.error('Failed to load thumbnail:', e);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadThumbnail();

    return () => {
      // Cleanup the object URL to free up memory
      if (thumbnail) {
        URL.revokeObjectURL(thumbnail);
      }
    };
  }, [fetchThumbnail]);

  if (loading)
    return (
      <div style={{ ...style, marginTop: 40, backgroundColor: 'white' }}>
        <Spinner />
      </div>
    );
  if (error) return <TableEmpty message="Failed to load thumbnail" />;
  return <img src={thumbnail} alt={alt} style={{ ...style, marginTop: 40 }} />;
};

ThumbnailLoader.propTypes = {
  fetchThumbnail: PropTypes.func.isRequired,
  alt: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

ThumbnailLoader.defaultProps = {
  alt: 'Thumbnail',
  style: { width: '250px', height: '250px' },
};

export default ThumbnailLoader;
