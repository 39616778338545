import { renderAPIBaseURL } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  renderPhotoBook(storyId, options) {
    return api.fetchAsAdmin('POST', `${renderAPIBaseURL}/pdf/${storyId}`, { ...options });
  },
  renderPhotoBookSpread(storyId, pageId) {
    return api.fetchAsAdmin('POST', `${renderAPIBaseURL}/pdf/${storyId}/${pageId}`);
  },
  renderPictureOrder(userId, orderId) {
    return api.fetchAsAdmin('POST', `${renderAPIBaseURL}/bitmap/`, { userId, orderId });
  },
  getPhotobookThumbnail(storyId, pageId, pageNumber) {
    return api.fetchBlobWithAuth(
      'GET',
      `${renderAPIBaseURL}/pdf/${storyId}/${pageId}/getThumbnail?pageNumber=${pageNumber}`,
    );
  },
};
