import { apiBaseUrl } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  writeReply(brand, slug, idempotent = true) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/saas/reamaze/writeResponse`, {
      brand,
      slug,
      idempotent,
    });
  },
};
