import React from 'react';
import userActions from 'actions/userActions';
import TableLoader from 'components/helpers/TableLoader';
import DetailPictureOrdersTab from './DetailPictureOrdersTab';
import DetailUserTab from './DetailUserTab';
import DetailSubscriptionTab from './DetailSubscriptionTab';
import DetailAddressesTab from './DetailAddressesTab';
import DetailOrdersTab from './DetailOrdersTab';
import DetailPaymentProfilesTab from './DetailPaymentProfilesTab';
import DetailPaymentsTab from './DetailPaymentsTab';
import DetailFulfillmentTab from './DetailFulfillmentTab';
import DetailScheduledEventsTab from './DetailScheduledEventsTab';
import DetailCreditsTab from './DetailCreditsTab';
import DetailPhotobooksTab from './DetailPhotobookTab';
import DetailDraftsTab from './DetailDraftsTab';
import botActions from '../../actions/botActions';

class DetailOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: '◌',
      startDate: undefined,
      endDate: undefined,
      submitDate: undefined,
      userId: '',
      visibleContent: 'profile',
      page: false,
      name: '',
      innitials: '',
    };
  }

  async componentDidMount() {
    try {
      const { match, tab, uid } = this.props;
      const userId = uid || (match && match.params.number);

      if (userId) {
        this.getUserName(userId);
        this.setState({ userId });
      }

      if (match) {
        this.handleMatch(match);
      }

      if (tab) {
        this.handleTabClick(tab);
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleMatch(match) {
    const visibleContent = match.params.tab
      ? match.params.tab
      : this.props.history.push(`${match.params.number}/profile`);

    this.setState({
      visibleContent,
      page: true,
    });
  }

  getUserName = async userId => {
    try {
      const user = await userActions.getUser(userId);
      const name = `${user.profile.name} ${user.profile.surname}`;
      const getInnitial = s => {
        const innitial = s ? s[0] : '';
        return innitial.toUpperCase();
      };
      const innitials = getInnitial(user.profile.name) + getInnitial(user.profile.surname);
      this.setState({ name, innitials });
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount = () => {
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  handleTabClick = tab => {
    this.setState({ count: '◌' });
    if (this.state.page) {
      this.props.history.push(tab);
    }
    this.setState({ visibleContent: tab });
  };

  handleCount = count => {
    this.setState({ count });
  };

  writeNewReply = async () => {
    try {
      const url = new URL(window.location.href);
      const brand = url.searchParams.get('brand');
      const slug = url.searchParams.get('slug');
      await botActions.writeReply(brand, slug, false);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    const { count, visibleContent, userId } = this.state;
    const tabs = {
      profile: {
        name: 'Profile',
        component: DetailUserTab,
      },
      detailOrders: {
        name: 'Orders',
        component: DetailOrdersTab,
      },
      payments: {
        name: 'Payments',
        component: DetailPaymentsTab,
      },
      subscription: {
        name: 'Subscription',
        component: DetailSubscriptionTab,
      },
      drafts: {
        name: 'Drafts',
        component: DetailDraftsTab,
      },
      // subscriptionv2: {
      //   name: 'Subscriptions v2 WIP',
      //   component: DetailSubscription2Tab,
      // },
      // cycles: {
      //   name: 'Cycles WIP',
      //   component: DetailCyclesTab,
      // },
      // entitlements: {
      //   name: 'Entitlements WIP',
      //   component: DetailEntitlementsTab,
      // },
      addresses: {
        name: 'Addresses',
        component: DetailAddressesTab,
      },
      photobooks: { name: 'Photo Books', component: DetailPhotobooksTab },
      pictureOrders: {
        name: 'Picture Orders',
        component: DetailPictureOrdersTab,
      },
      fulfillmentQueue: {
        name: 'Fulfillment',
        component: DetailFulfillmentTab,
      },
      paymentMethods: {
        name: 'Payment Methods',
        component: DetailPaymentProfilesTab,
      },
      scheduled: {
        name: 'Scheduled Activities',
        component: DetailScheduledEventsTab,
      },
      credits: {
        name: 'Credits',
        component: DetailCreditsTab,
      },
    };
    const selectedTab = tabs[visibleContent];

    if (!userId) return <TableLoader />;

    return (
      <div className={this.inIframe() ? 'detail-overview iframe' : 'detail-overview'}>
        {this.state.page && this.inIframe() && (
          <>
            <a href={window.location.href} target="_blank" rel="noopener noreferrer" className="break-out">
              Open in new window
            </a>
            <button type="button" onClick={this.writeNewReply}>
              Write new reply
            </button>
          </>
        )}
        <nav className="header">
          <ul id="menu">
            <li className="user-name">
              <span className="innitials">{this.state.innitials}</span>
              {this.state.name}
            </li>
            {Object.entries(tabs).map(([key, tab]) => (
              <li key={key}>
                <button
                  type="button"
                  onClick={this.handleTabClick.bind(this, key)}
                  className={visibleContent === key ? 'selected tab' : 'tab'}
                >
                  {tab.name}
                  {visibleContent === key && <span className="count">{count.toLocaleString()}</span>}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <div className="detail-content">
          {Object.keys(tabs).includes(visibleContent) && (
            <selectedTab.component
              uid={userId}
              user={this.props.user}
              count={this.handleCount}
              toastOpen={this.props.toastOpen}
            />
          )}
        </div>
      </div>
    );
  }
}
export default DetailOverview;
