import moment from 'moment';
import { auth, apiFunctionsUrl, apiBaseUrl, database, ordersAPIBaseURL, storage } from 'config/base';
import firebaseActions from 'actions/firebaseActions';
// import pictureActions from 'actions/picturesActions';
import api from 'components/helpers/utils/api';

export default {
  archiveOrder(userId, orderId) {
    const body = {
      userId,
      orderId,
    };
    return api.fetchAsAdmin('POST', `${apiFunctionsUrl}/queue/${orderId}/archive`, body);
  },

  archiveWithoutEmail: async orderId => {
    try {
      const queueRef = database.ref('fulfillment').child('queue');

      const archiveRef = queueRef.child('archive');
      const pendingUploadRef = queueRef.child('pendingUpload');

      const archiveOrderRef = archiveRef.child(orderId);
      const pendingUploadOrderRef = pendingUploadRef.child(orderId);

      const pendingUploadOrder = await pendingUploadOrderRef.once('value');

      if (pendingUploadOrder.exists()) {
        const order = await pendingUploadOrder.val();

        await archiveOrderRef.update(order);
        await pendingUploadOrderRef.remove();
      }
    } catch (error) {
      console.error(error);
    }
  },
  getOrderStatus: async (userId, orderId) => {
    try {
      const queueRef = database.ref('fulfillment').child('queue');

      const archiveRef = queueRef.child('archive');
      const pendingUploadRef = queueRef.child('pendingUpload');

      const archiveOrderRef = archiveRef.child(orderId);
      const pendingUploadOrderRef = pendingUploadRef.child(orderId);

      const pendingUploadOrderSnapshot = await pendingUploadOrderRef.once('value');
      if (pendingUploadOrderSnapshot.exists()) {
        return 'submitted';
      }

      const archiveOrderSnapshot = await archiveOrderRef.once('value');

      if (archiveOrderSnapshot.exists()) {
        return 'shipped';
      }

      return 'created';
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  updatePictureOrder(userId, orderId, order) {
    console.info(`Updating pictureOrder: ${userId}/${orderId}`);
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/pictureOrders/${userId}/${orderId}`, order);
  },

  importOrderToPictureOrder(userId, orderId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/pictureOrders/importFromEditor/${userId}/${orderId}`);
  },

  async createPictureOrder(userId, packageId = 200) {
    const user = await firebaseActions.getfirebaseActions(`user/${userId}`);

    if (!user) {
      alert(`The user ${userId} does not have a profile`);
    }

    const { profile } = user;
    const currentUser = await auth.currentUser;
    // eslint-disable-next-line
    const addressId = user.metadata && user.metadata.activeAddress ? user.metadata.activeAddress : 'subscriptionV0';

    const orderReq = {
      userId,
      countryCode: profile.countryCode,
      userAgent: {
        source: 'klikkie-admin',
        userId: currentUser.uid,
        // TODO: implement version
        // version: ???,
      },
      products: {
        [packageId]: 1,
      },
      addressId,
      invoiceAddressId: 'klikkie',
      status: 'fulfilled',
      statusPayment: 'fulfilled',
      isPrepaid: true,
    };

    return api.fetchAsAdmin('POST', `${ordersAPIBaseURL}`, orderReq);
  },

  removePictureOrder(userId, orderId) {
    return api.fetchAsAdmin('DELETE', `${apiBaseUrl}/pictureOrders/${userId}/${orderId}`);
  },

  resetPictureOrder(userId, orderId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/pictureOrders/${userId}/${orderId}/reset`);
  },

  queueRenderedFilesCustom(orderId, folderName) {
    console.info(`queueing files ${orderId} in: ${folderName}`);
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fulfillment/westerduin/${orderId}/copyToCustomFolder`, {
      folderName,
    });
  },

  queueFilesCustom(orderId, folderName) {
    console.info(`queueing files ${orderId} in: ${folderName}`);
    const body = {
      orderId,
      folderName,
    };
    return api.fetchAsAdmin('POST', `${apiFunctionsUrl}/queue/${orderId}/queueFiles`, body);
  },

  resetOrder(orderId, userId) {
    const body = {
      orderId,
      userId,
    };
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/pubsub/recover/payment-created/user`, body);
  },

  queueFilesForUser(userId, orderId) {
    if (!userId) throw new Error('No userId provided');
    if (!orderId) throw new Error('No orderId provided');
    return api.fetchAsAdmin('POST', `${apiFunctionsUrl}/queue/${orderId}/queueFilesForUser/${userId}`).then(r => r[0]);
  },

  async freeOrder(userId) {
    const result = await api.fetchAsAdmin('POST', `${apiBaseUrl}/subscriptions/${userId}/free-next-order`);
    return `User is prepaid until: ${result[0].prepaidUntil}`;
  },

  async chargeAccount(orderId) {
    await api.fetchAsAdmin('POST', `${apiBaseUrl}/checkout/charge/${orderId}`);
    return `${orderId} is charged`;
  },

  getOrders(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/orders/user/${userId}`);
  },

  getDiscountCodeRedeems(discountCode) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/redeems/${discountCode}`);
  },

  getDiscountCodeClaims(discountCode) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/claims/${discountCode}`);
  },

  getUserOrdersAPIOrders(userId) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/user/${userId}`);
  },

  removeOrderAPIOrder(orderId) {
    return api.fetchAsAdmin('DELETE', `${ordersAPIBaseURL}/${orderId}`);
  },

  getOrdersAPIOrders(date) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/date/${date}`);
  },

  getOrdersAPIOrdersByDiscountCode(discountCode) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/discountCode/${discountCode}`);
  },

  getOrderAPIOrder(orderId) {
    return api.fetchAsAdmin('GET', `${ordersAPIBaseURL}/${orderId}`).then(r => r[0]);
  },

  putOrderAPI(orderId, data) {
    return api.fetchAsAdmin('PUT', `${ordersAPIBaseURL}/${orderId}`, data);
  },

  putOrderAPIAddresses(orderId, data) {
    return api.fetchAsAdmin('PUT', `${ordersAPIBaseURL}/${orderId}/addresses`, data);
  },

  putOrderAPIOrderStatus(orderId, status) {
    return api.fetchAsAdmin('PUT', `${ordersAPIBaseURL}/${orderId}/status`, { status });
  },

  getPictureOrders(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/pictureOrders/${userId}`);
  },

  getPictureOrder(userId, orderId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/pictureOrders/${userId}/${orderId}`);
  },
  putPictureOrder(userId, orderId, body) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/pictureOrders/${userId}/${orderId}`, body);
  },
  async getPictureOrderPictures(link) {
    try {
      const renderedOrderRef = storage.ref(link);

      const filePromises = [];
      const folders = await renderedOrderRef.listAll();
      folders.prefixes.forEach(async folderRef => {
        filePromises.push(folderRef.listAll());
      });
      const fileRefs = await Promise.all(filePromises);
      const urlPromises = [];
      fileRefs.forEach(fileRef => {
        fileRef.items.forEach(async itemRef => {
          urlPromises.push(itemRef.getDownloadURL());
        });
      });
      const pictures = await Promise.all(urlPromises);

      const thumnailsPerType = {};
      const picturesPerType = {};
      pictures.forEach(picture => {
        let type = picture.split('%2F')[4];
        if (type === 'xl' || type === 'strip') type = 'xl/strip';
        if (picture.includes('thumb_')) {
          if (!thumnailsPerType[type]) thumnailsPerType[type] = [];
          thumnailsPerType[type].push(picture);
        } else {
          if (!picturesPerType[type]) picturesPerType[type] = [];
          picturesPerType[type].push(picture);
        }
      });
      return [picturesPerType, thumnailsPerType];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getOrder(orderId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/orders/${orderId}`);
  },

  queryApi(url, object) {
    let result;
    return fetch(url, object)
      .then(res => {
        result = res;
        return res.text();
      })
      .then(value => {
        if (result.status === 200) {
          console.info(value);

          return value;
        }
        console.error(value);
        alert(`Oops! \n\n${value}`);
        return value;
      })
      .catch(console.info);
  },

  getToken() {
    return auth.currentUser
      .getIdToken()
      .then(token => token.toString())
      .catch();
  },

  compileDateWithHour(timestamp) {
    if (timestamp === undefined) {
      return 'none';
    }

    const date = moment(timestamp);

    return date.format('MMMM DD HH:mm');
  },

  compileDate(date) {
    if (date === undefined) return 'no date';
    if (typeof date === 'number') {
      return new Date(date).toISOString().substring(0, 10);
    }
    if (typeof date === 'string') {
      return date.substring(0, 10);
      //  return newDate = new Date(date).toISOString().substring(0, 10)
    }
    return date;
  },
  compileDateAndTime(date) {
    if (date === undefined) return 'no date';
    const newDate = new Date(date).toISOString();
    return `${newDate.substring(0, 10)}\n ${newDate.substring(11, 16)}`;
  },
  compileDateAndTimeNoLineBreak(date) {
    if (date === undefined) return 'no date';
    const newDate = new Date(date).toISOString();
    return `${newDate.substring(0, 10)}  ${newDate.substring(11, 16)}`;
  },
  compileTime(date) {
    if (date === undefined) return 'no date';
    const newDate = new Date(date).toISOString();
    return newDate.split('T')[1].split('.')[0];
  },
  compileCouponDate(date) {
    if (date === undefined) return 'no date';

    return new Date(date).toISOString().substring(0, 10);
  },
  compileYearMonth(date) {
    if (date === undefined) return 'no date';
    if (typeof date === 'number') {
      return new Date(date).toISOString().substring(0, 7);
    }
    if (typeof date === 'string') {
      return date.substring(0, 7);
    }
    return date;
  },
};
