/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { MdSearch, MdPerson } from 'react-icons/md';
import { IconContext } from 'react-icons';
import Highlighter from 'react-highlight-words';
import { database } from 'config/base';
import Klikkie from '_klikkie';
import Spinner from './Spinner';
import DataUtils from './utils/data';

const klikkie = new Klikkie();

const isValidKey = key => {
  if (!key || key.length === 0) {
    return false;
  }
  if (key.length < 8) {
    return false;
  }
  const forbiddenChars = ['.', '$', '#', '[', ']', '/', ')'];
  return !forbiddenChars.some(char => key.includes(char));
};

class SearchUser extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      searching: false,
    };
    this.query = React.createRef();
  }

  searchHandler = event => {
    if (event.keyCode === 27) this.setState({ rows: [] });
    if (event.key !== 'Enter') return;
    event.preventDefault();
    const query = this.query.current.value;
    let escapedQuery = this.query.current.value;
    const forbiddenChars = ['\\.', '\\#', '\\$', '\\[', '\\]'];

    forbiddenChars.forEach(forbiddenChar => {
      escapedQuery = escapedQuery.replace(new RegExp(forbiddenChar, 'g'), '');
    });

    if (!escapedQuery || escapedQuery.length < 4) {
      this.setState({
        rows: [{ 'profile.name': 'Type at least 4 characters (excl. dots)...', 'profile.surname': '' }],
      });
    } else {
      this.search(escapedQuery, query);
    }
  };

  sortRowsAlphabetical = (rowA, rowB) => {
    try {
      if (rowA['profile.name'] !== rowB['profile.name']) {
        return rowA['profile.name'].localeCompare(rowB['profile.name']);
      }

      return rowA['profile.surname'].localeCompare(rowB['profile.surname']);
    } catch {
      return 0;
    }
  };

  getUniqueSortedRows = rows => {
    const uniqueIds = {};

    return rows
      .filter(({ uid }) => {
        // There might be an edge case where uid is not there
        if (!uid) {
          return true;
        }

        if (uid in uniqueIds) {
          return false;
        }

        uniqueIds[uid] = true;
        return true;
      })
      .sort(this.sortRowsAlphabetical);
  };

  search = async (keyQuery, query) => {
    try {
      this.setState({ rows: [], searching: true });
      const titleCaseQuery = this.toTitleCase(query);
      const ref = database.ref('user');

      if (query.length < 4) {
        throw new Error('Query too short');
      }

      const snapshots = await Promise.all([
        ref.child(keyQuery).once('value'),
        ref
          .orderByChild('profile/name')
          .equalTo(query)
          .once('value'),
        ref
          .orderByChild('profile/surname')
          .equalTo(query)
          .once('value'),
        ref
          .orderByChild('profile/name')
          .equalTo(titleCaseQuery)
          .once('value'),
        ref
          .orderByChild('profile/surname')
          .equalTo(titleCaseQuery)
          .once('value'),
        ref
          .orderByChild('profile/email')
          .startAt(query)
          .endAt(`${query}\uf8ff`)
          .limitToLast(100)
          .once('value'),
        ref
          .orderByChild('profile/phonenumber')
          .equalTo(query)
          .once('value'),
      ]);

      let rows = [];
      snapshots
        .filter(snapshot => snapshot && snapshot.exists())
        .map(snapshot => {
          let users = snapshot.val();

          // Convert single user to users
          if ('profile' in users) {
            users = { [snapshot.key]: users };
          }

          Object.entries(users).forEach(([userId, user]) => {
            const child = DataUtils.flattenObject(user);
            child.uid = userId;
            child['profile.email'] = child['profile.email'] || 'No email';
            child.tab = 'profile';
            rows = [...rows, child];
            return rows;
          });
          return true;
        });
      const order = await this.getOrder(query);
      if (order) rows.push(order);
      if (rows.length === 0) rows.push({ 'profile.name': `${query} not found...`, 'profile.surname': '' });
      return this.setState({
        rows: this.getUniqueSortedRows(rows),
        searching: false,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getOrder = async query => {
    try {
      if (!isValidKey(query)) return false;
      const order = await klikkie.fulfillment(query).get();
      if (!order) return false;
      return {
        uid: order.userId,
        'profile.name': order.id,
        'profile.surname': `${order.status} in fulfillment`,
        'profile.email': `Order of ${order.address ? order.address.fullname : order.userId}`,
        tab: 'fulfillmentQueue',
      };
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  clearSearch = () => {
    setTimeout(() => {
      this.query.current.value = '';
      this.setState({ rows: [] });
    }, 200);
  };

  toTitleCase = str => {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  render() {
    return (
      <span className="searchUser">
        <form className="searchForm">
          <fieldset className="fieldSetUser">
            <IconContext.Provider
              value={{
                style: {
                  fontSize: '1.8em',
                  color: '#757575',
                  position: 'relative',
                  marginLeft: '-35px',
                  top: '7px',
                },
              }}
            >
              <input
                className={this.state.rows.length ? 'searchInput searching' : 'searchInput'}
                ref={this.query}
                id="search"
                data-lpignore="true"
                onFocus={this.searchHandler}
                onKeyDown={this.searchHandler}
                onBlur={this.clearSearch}
                placeholder="Search for name, email, userId or orderId..."
              />
              {!this.state.searching && <MdSearch />}
              {this.state.searching && <Spinner />}
            </IconContext.Provider>
          </fieldset>
        </form>

        {this.state.rows.length !== 0 && (
          <span>
            <ul className="results">
              {this.state.rows.map(row => (
                <li key={row.uid} onClick={() => this.props.detailOpen(row.uid, row.tab)}>
                  <div className="resultsIcon">
                    <MdPerson />
                  </div>
                  <div className="resultsText">
                    <Highlighter
                      className="name"
                      highlightClassName="YourHighlightClass"
                      searchWords={[this.query.current.value]}
                      autoEscape
                      textToHighlight={`${row['profile.name']} ${row['profile.surname']}`}
                    />
                    <Highlighter
                      className="email"
                      highlightClassName="YourHighlightClass"
                      searchWords={[this.query.current.value]}
                      autoEscape
                      textToHighlight={row['profile.email']}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className="after" />
          </span>
        )}
      </span>
    );
  }
}

export default SearchUser;
