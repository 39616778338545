import React, { Component } from 'react';
import productPackageActions from 'actions/productPackageActions';

class FulfillmentContentPanelProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packageId: '',
      product: {},
      units: 0,
    };
  }

  async componentDidMount() {
    try {
      const { packageId, units } = this.props;
      const product = await productPackageActions.getPackage(packageId);
      if (!product) throw new Error(`No product package found with #SKU ${packageId}`);

      this.setState({ packageId, product, units });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { packageId, product, units } = this.state;
    return (
      <span>
        {product && (
          <span>
            <h1>
              {units}X{packageId}:{product.type}/{product.variant}
            </h1>
            <img className="product-image" src={product.thumbSrc} alt={product.variant} />
          </span>
        )}
      </span>
    );
  }
}

export default FulfillmentContentPanelProduct;
