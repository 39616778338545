import GenericCopyButton from 'components/buttons/GenericCopyButton';
import StorageImage from 'components/helpers/formatters/StorageImage';
import React from 'react';

const FulfillmentContentPanelAlbumEditor = props => {
  const { json, saveCover, savePage } = props;
  const responseBody = {};

  const addThumbPrefix = filePath => {
    const pathParts = filePath.split('/');
    const fileName = pathParts.pop();
    const thumbFileName = `thumb_${fileName}`;
    pathParts.push(thumbFileName);
    return pathParts.join('/');
  };

  const form = element => {
    if (element.type === 'image') {
      const thumbnail = element.croppedContent
        ? addThumbPrefix(element.croppedContent)
        : addThumbPrefix(element.content);
      return (
        <div
          key={`${element.id}_label`}
          id={`${element.id}_label`}
          style={{ border: '1px solid lightgrey', padding: '10px', marginBottom: '10px' }}
        >
          <label key={`${element.id}_label`} id={`${element.id}_label`} htmlFor={element.id}>
            {element.id} <br />
            content:
            <input
              id={`${element.id}_image`}
              name={element.id}
              type="text"
              placeholder={element.placeholder?.nl}
              defaultValue={element.content}
            />
            <br />
            cropped content:
            <input
              id={`${element.id}_crop`}
              name={`${element.id}_crop`}
              type="text"
              placeholder={element.placeholder?.nl}
              defaultValue={element.croppedContent}
            />
            <br />
            remote reference:
            <input
              id={`${element.id}_remote`}
              name={`${element.id}_remote`}
              type="text"
              placeholder={element.placeholder?.nl}
              defaultValue={element.remoteReference}
            />
            <br />
            <StorageImage styleObj={{ width: '100px' }} value={thumbnail} />
            <br />
          </label>
        </div>
      );
    }

    function handleKeyDown(e) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }

    return (
      <label key={`${element.id}_label`} id={`${element.id}_label`} htmlFor={element.id}>
        {element.id}
        <textarea
          id={`${element.id}_textarea`}
          type="text"
          name={element.id}
          defaultValue={element.content}
          onKeyUp={handleKeyDown}
          onFocus={handleKeyDown}
        />
        <br />
      </label>
    );
  };

  const isCover = json.tId?.includes('-cover');

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const newData = { ...json };
      // eslint-disable-next-line no-return-assign
      formData.forEach((value, property) => (responseBody[property] = value));

      newData.e.forEach((element, i) => {
        newData.e[i].content = responseBody[element.id];
        newData.e[i].croppedContent = responseBody[`${element.id}_crop`];
        newData.e[i].remoteReference = responseBody[`${element.id}_remote`];
      });

      if (isCover) {
        return saveCover(newData);
      }

      return savePage(newData);
    } catch (error) {
      console.error(error);
      return props.toastOpen(error, 'error');
    }
  };
  return (
    <form
      style={{ padding: '10px', margin: '40px', background: 'white', width: '500px', color: 'black' }}
      onSubmit={handleSubmit}
    >
      {props.json.id ? <GenericCopyButton title="Copy page id" text="Page id" value={props.json.id} /> : null}
      <br />
      {json?.e?.length > 0 && json.e.sort((a, b) => (a.id > b.id ? 1 : -1)).map(form)}
      <input type="submit" value={isCover ? 'Save Cover' : 'Save and Render Spread'} />
    </form>
  );
};

export default FulfillmentContentPanelAlbumEditor;
